.separator {
  position: absolute;
  top: auto;
  left: 0;
  right: 0;
  width: 100%;
  height: 150px;
  z-index: 1;
  transform: translate(0,1px);
  overflow: hidden;
  pointer-events: none;

  svg {
      position: absolute;
      pointer-events: none;
  }
}

.separator-top {
  top: 0;
  bottom: auto;

  svg {
      top: 0;
  }
}

.separator-bottom {
  top: auto;
  bottom: 0;

  svg {
      bottom: 0;
  }
}

.separator-inverse {
  transform: rotate(180deg);
}

// Styles

.separator-skew {
  height: 80px;
  @include media-breakpoint-down(md) {
      z-index: 0;
  }
}



.mt-md,
.my-md
{
  margin-top: 4rem !important;
}

.mr-md,
.mx-md
{
  margin-right: 4rem !important;
}

.mb-md,
.my-md
{
  margin-bottom: 4rem !important;
}

.ml-md,
.mx-md
{
  margin-left: 4rem !important;
}

.m-lg
{
  margin: 6rem !important;
}

.mt-lg,
.my-lg
{
  margin-top: 6rem !important;
}

.mr-lg,
.mx-lg
{
  margin-right: 6rem !important;
}

.mb-lg,
.my-lg
{
  margin-bottom: 6rem !important;
}

.ml-lg,
.mx-lg
{
  margin-left: 6rem !important;
}

.m-xl
{
  margin: 8rem !important;
}

.mt-xl,
.my-xl
{
  margin-top: 8rem !important;
}

.mr-xl,
.mx-xl
{
  margin-right: 8rem !important;
}

.mb-xl,
.my-xl
{
  margin-bottom: 8rem !important;
}

.ml-xl,
.mx-xl
{
  margin-left: 8rem !important;
}


.pt-lg,
.py-lg
{
  padding-top: 6rem !important;
}

.pr-lg,
.px-lg
{
  padding-right: 6rem !important;
}

.pb-lg,
.py-lg
{
  padding-bottom: 6rem !important;
}

.pl-lg,
.px-lg
{
  padding-left: 6rem !important;
}

.p-xl
{
  padding: 8rem !important;
}

.pt-xl,
.py-xl
{
  padding-top: 8rem !important;
}

.pr-xl,
.px-xl
{
  padding-right: 8rem !important;
}

.pb-xl,
.py-xl
{
  padding-bottom: 8rem !important;
}

.pl-xl,
.px-xl
{
  padding-left: 8rem !important;
}

.m-auto
{
  margin: auto !important;
}

.mt-auto,
.my-auto
{
  margin-top: auto !important;
}

.mr-auto,
.mx-auto
{
  margin-right: auto !important;
}

.mb-auto,
.my-auto
{
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto
{
  margin-left: auto !important;
}