
.posts {
  font-family: "Poppins", sans-serif !important;

  .selectable-input {
    all: inherit;
    border: none;
    background: none;
    width: 100%;
    height: 100%;
    resize: none;
    word-wrap: break-word;
    outline: none;
    padding: 0;
    margin: 0;
    white-space: pre-wrap;
  }
  h2, .h2 {
    font-size: 2rem;
  }
  h5, .h5 {
    font-size: 1.25rem;
  }
  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }

  h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    margin-bottom: 0.5rem;
    font-family: inherit;
    font-weight: 400;
    line-height: 1.5;
    color: #32325d;
  }

  .ql-align-justify {
    text-align: justify;
  }

  .ql-align-center {
    text-align: center;
  }

  .ql-align-right {
    text-align: right;
  }
  .card {
    position: relative;
    border-radius: 0.5em;
    z-index: 5;
    .postt {
    min-height: 80vh;
    .post-img {
      justify-content: center;
      img {
        width: 100%;
        max-height: 616px;
        border: 1px inset black;
        border-radius: 5px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 
                    0 6px 20px rgba(0, 0, 0, 0.1); 
      }
    }
    .post-content{
      all: initial;
      font-family: inherit;
      img {
        max-width: 100%;
        height: auto;
      }
  
      margin: 0;
      font-family: "Poppins", sans-serif;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #525f7f;
      text-align: left;
      background-color: #fff;
    }
  
    .under-img {
      margin-left: 0;
      margin-right: 0;
      padding: 5px;
      .socials {
        a {
          padding: 5px;
          font-size: 25px;
          color: black;
          svg {
            transition: transform ease 0.3s, color ease 0.3s;
          }
          
          &:hover {
            svg {
              transform: scale(1.1);
              color: mix(white, black, 25%);
            }
          }
          
          &:active {
            svg {
              transform: scale(0.85);
              color: mix(white, black, 75%);
  
            }
          }
        }
      }
      .date {
        margin-left: auto;
        font-size: small;
      }
    }
  
    }
  }

  .post-image img {
    overflow: visible;
    width: 100%;
    max-height: 616px;
    border: 1px inset black;
    border-radius: 5px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 
                0 6px 20px rgba(0, 0, 0, 0.1); 

    &:hover {
      transform: scale(1.01); 
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 
                  0 12px 40px rgba(0, 0, 0, 0.2); 
    }
  }

  //SECTION
  .section-shaped {
    position: relative;
    overflow: hidden;

    &.section-hero {
      &:before {
        top: 680px;
      }
    }

    .stars-and-coded{
        margin-top: 8rem;
    }

    .shape {
        position: absolute;
        top: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        background: linear-gradient(135deg, #ff9bcb, #7c14a4);

        span {
            position: absolute;
        }

        +.container {
            position: relative;
            height: 100%;
        }

        &.shape-skew {

            +.container {
                padding-top: 0;

                .col {
                    margin-top: -100px;
                }
            }

            + .shape-container {
                padding-top: 18rem;
                padding-bottom: 19rem;
            }
        }
    }
  }

  .section-profile-cover {
    height: 580px;
    background-size: cover;
    background-position: center center;
  }
}


.ql-editor p {
  margin-top: 0 !important;
  margin-bottom: 1rem !important;
}

.simpliquill {
  .quill {
    font-family: "Poppins", sans-serif !important;
    position: relative; 
    .ql-container {
      flex: 1;
      position: relative;
      all: inherit !important;
      font-family: inherit;
      .ql-editor {
        padding: 0;
      }
    }
    
    .ql-toolbar {
      background-color: white;
      position: sticky;
      top: 0;
      z-index: 1
    }
  }
  .ql-color-picker-parent {
    position: absolute;
    height: 100%;
    width: 100%;
    .quill-color-picker {
      position: sticky;
      top: 70px;
      right: 0; 
      z-index: 2;
    }  
  }
}



@include media-breakpoint-down(md) {
  .section-profile-cover {
      height: 400px;
  }
}

@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
      max-width: 1040px !important;
  }
}