//
// Chart
//

.chart {
    position: relative;
    height: $chart-height;
}


// Size variations

.chart-sm {
    height: $chart-height-sm;
}


// Legend

.chart-legend {
    display: flex;
    justify-content: center;
    margin-top: $chart-legend-margin-top;
    font-size: $chart-legend-font-size;
    text-align: center;
    color: $chart-legend-color;
}

.chart-legend-item {
    display: inline-flex;
    align-items: center;

    + .chart-legend-item {
        margin-left: 1rem;
    }
}

.chart-legend-indicator {
    display: inline-block;
    width: 0.5rem;
    height: 0.5rem;
    margin-right: 0.375rem;
    border-radius: 50%;
}


// Tooltip

#chart-tooltip {
    z-index: 0;

    .arrow {
        top: 100%;
        left: 50%;
        transform: translateX(-50%) translateX(-.5rem);
    }
}


// Chart info overlay

.chart-info-overlay {
  position: absolute;
  top: 0;
  left: 5%;
  max-width: 350px;
  padding: 20px;
  z-index: 1;
}

.metric-display {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.metric-ball {
  width: 12px;
  height: 12px; 
  border-radius: 50%;
  border: 2px black solid;
}

.metric-label {
  font-size: 14px;
}

.metric-number {
  font-size: 18px; 
  font-weight: bold;
}
