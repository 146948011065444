//
// Type
//


// Paragraphs


html {
  scrollbar-width: none;
}
p {
	font-size: $paragraph-font-size;
	font-weight: $paragraph-font-weight;
	line-height: $paragraph-line-height;    
}

.lead {
	font-size: $lead-font-size;
  	font-weight: $lead-font-weight;
	line-height: $paragraph-line-height;
	margin-top: 1.5rem;

	+ .btn-wrapper {
		margin-top: 3rem;
	}
}

.description {
	font-size: $font-size-sm;
}
.disabled {
  pointer-events: none;
}